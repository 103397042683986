import $ from "jquery"
const token = $("meta[name=\"csrf_token\"]").attr("content")

export default class CsrfProtector {
  static getToken() {
    return token
  }

  // CSRFトークンをformに埋め込むメソッド
  static injectTokenToForm(form) {
    const $form = $(form)
    if (
      $form.attr("method") && $form.attr("method").toUpperCase() !== "GET"
      && !$form.find("input[name=csrf_token]").length
    ) {
      $form.append($("<input>").attr({
        type: "hidden",
        name: "csrf_token",
        value: token,
      }))
    }
  }
}
