import _ from "lodash";

const RE_STR_HTTP = "https?://";
const RE_STR_HTTP_SUBDOMAIN = `${RE_STR_HTTP}(?:[^\.]+\.)?`;

// 参考: http://oembed.com/#section7 の URL Scheme
// URL Schemeがない場合は独自調べ
const RULES = {
  youtube: {
    urlSchemes: [
      `${RE_STR_HTTP_SUBDOMAIN}youtube\.com/watch\\?v=(.+)`,
      `${RE_STR_HTTP}youtu.be/(.*)`
    ],
    renderHtml: _.template("<iframe width=\"<%- width %>\" height=\"<%- height %>\" src=\"https://www.youtube.com/embed/<%- id %>\" frameborder=\"0\" allowfullscreen></iframe>")
  },
  vimeo: {
    urlSchemes: [
      `${RE_STR_HTTP_SUBDOMAIN}vimeo\.com/(.+)` // 正確には \d+ のようであるが良しとする
    ],
    renderHtml: _.template("<iframe src=\"https://player.vimeo.com/video/<%- id %>\" width=\"<%- width %>\" height=\"<%- height %>\" frameborder=\"0\" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>")
  },
  vine: {
    urlSchemes: [
      `${RE_STR_HTTP_SUBDOMAIN}vine\.co/v/(.+)`
    ],
    renderHtml: _.template("<iframe src=\"https://vine.co/v/<%- id %>/embed/simple\" width=\"<%- width %>\" height=\"<%- height %>\" frameborder=\"0\"></iframe><script src=\"https://platform.vine.co/static/scripts/embed.js\"></script>")
  },
  /**
   * ニコニコ動画がSSLに対応していないため、一時的にembedのサポートを外している
   */
  /*
  niconico: {
    urlSchemes: [
      RE_STR_HTTP_SUBDOMAIN + "nicovideo.jp/watch/(.+)"
    ],
    renderHtml: function(opts) {
      // niconicoはスクリプト内でdocument.writeするのでハックする
      // http://mementoo.info/archives/1557
      let write = document.write;
      document.write = function(msg) {
        opts.onDidCreateHtml(msg);
        document.write = write;
      };
      // ex. <script type="text/javascript" src="http://ext.nicovideo.jp/thumb_watch/sm12790288"></script>
      let script = "<scr" + "ipt src=\"http://ext.nicovideo.jp/thumb_watch/" + opts.id + "?w=" + opts.width + "&h=" + opts.height + "\"></scr" + "ipt>";
      opts.onDidCreateScript(script);
      return null;
    }
  },
  */
};

function matchUrl(url, rule) {
  // 結合して一度でマッチング
  const re = new RegExp(
    `^${rule.urlSchemes
      .map(function(scheme) {
        return `(?:${scheme})`;
      })
      .join("|")}`
  );
  const matches = url.match(re);
  if (!matches) {
    return null;
  }
  const id = _.find(matches.slice(1), function(m) {
    return m !== void 0;
  });
  return id;
}

/**
 * @param  {object} opts 関数冒頭を参照
 * @return {boolean} 変換可能ならtrue
 */
export default function embed(opts) {
  opts = _.assign({
    // 視聴URL (required)
    url: "",
    // 埋め込み幅
    width: 420,   // YouTubeの埋め込みコードのデフォルトサイズ
    // 省略した場合 floor(width/aspect)
    height: null, // デフォルトは 420 / 1.33 = 315 になる
    // アスペクト比 (width/height)
    aspect: 1.33,  // 映画のアスペクト比
    // document.writeハックのため、scriptを埋め込む必要がある場合がある。
    // その際このコールバックにscriptのHTMLが渡る。
    // (required)
    onDidCreateScript(script) { console.error(script); },
    // htmlが生成されたら呼ばれる。 (required)
    onDidCreateHtml(html) { console.error(html); }
  }, opts || {});
  opts.height = opts.height || Math.floor(opts.width / opts.aspect);

  if (!new RegExp(`^${RE_STR_HTTP_SUBDOMAIN}`).test(opts.url)) {
    return false;
  }

  for (const name in RULES) {
    const rule = RULES[name];
    const id = matchUrl(opts.url, rule);
    if (!id) {
      continue;
    }
    const html = rule.renderHtml(_.assign({id}, opts));
    if (html) {
      opts.onDidCreateHtml(html);
    }
    return true;
  }

  return false;
}
