import MAL from "../../common/namespace";
import _ from "lodash";
import utils from "../../common/modules/utils";

_.assign(MAL, {
  SkinAd: null,
  adRefresh: null,
  SNSFunc: null,
  headerNotification: {
    items: [],
    countDigest: 0,
    dropdownOpenedAt: 0,
    hasNewItems: false,
    templates: {
      root: "",
      button: "",
      dropdown: "",
      itemFriendRequest: "",
      itemProfileComment: ""
    }
  },
  notification: {
    items: [],
    historyItems: [],
    templates: {
      main: "",
      list: "",
      itemFriendRequest: "",
      itemProfileComment: ""
    }
  },
  episodeVideo: {
    aroundVideos: ""
  },
  legacy: {
    utils: utils
  },
});

export default MAL;
