/**
 * header.jsは全ページのヘッダでロードされ、即時実行されます。
 * ここでjquery, lodash, noty, MAL namespaceがグローバルに定義されます。
 */

import MAL from "./namespace"
import _ from "lodash"
import $ from "jquery"
import noty from "../../common/modules/notify"
import axios from "axios"

window.MAL = MAL
window.$ = window.jQuery = $
window.$.readyExeption = (e) => {
  console.error(e)
}
window.$.support.opacity = true // fancyboxでデグレしたため、後方互換性のために残している模様
window._ = _
window.noty = noty
window.axios = axios
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"
axios.interceptors.request.use(config => {
  // カスタムヘッダーにリファラを格納。ログイン後のリダイレクト等に使用する
  config.headers["X-Referral-Path"] = location.href.replace(location.origin, "")
  return config
})

// jQuery Migrate 設定
$.migrateMute = true

// jquery拡張
require("./jquery-migrate-3.0.0")
require("./jquery.hoverintent")
require("./jquery.fancybox.pack")
require("../../common/jquery.exists")
require("../../common/jquery.url2embed")
require("./jquery.cookie-1.4.1.min")

// CSRF対策
require("../../common/csrfProtection")

// Skin広告
require("./skinAds.js")

// 広告リフレッシュのモジュール
require("../../common/ad-refresh")

// SNS投稿関係のモジュール
require("./SNSFunc.js")

// GA event
require("../../common/ga")

// smart ad server
require("../../common/smart-ad-server")
