"use strict";

/*
 * Skin広告配信用のスクリプト
 * https://wiki.dena.jp/pages/viewpage.action?pageId=85071774
 * https://wiki.dena.jp/pages/viewpage.action?pageId=85335467
 */

import Cookies from "js-cookie";
import $ from "jquery";

// 現ページのSkinのスロットコード
let slotCode;

// スキンの親divのjQueryオブジェクト
let $skinLeft, $skinRight;

// 広告部分のjQueryオブジェクト
let $skinLeftAbsolute, $skinRightAbsolute;
let $skinLeftFixed, $skinRightFixed;
let $trackingPixel;

// 広告が隠れた際の挙動
let foregroundAlign;

// 広告のサイズ(デフォルト値を設定)
let adsLeftWidth   = 180;
let adsRightWidth  = 180;
let adsLeftHeight  = 600;
let adsRightHeight = 600;

/**
 * Skin広告を準備して配信するメソッド。
 * 広告のIFrame内からこのメソッドを呼ぶことで配信が開始される。
 *
 * @param {
 *   {
 *     foreAlign          : "innerside" | "centered" | "outerside",
 *
 *     leftAdType         : "Image" | "Flash",
 *     leftForeFile       : string,
 *     leftForeWidth      : int,
 *     leftForeHeight     : int,
 *     leftForeAttachment : "fixed" | "scroll",
 *     leftBackImage      : string,
 *     leftBackHAlign     : "left" | "center" | "right",
 *     leftBackVAlign     : "top" | "center" | "bottom",
 *     leftBackRepeat     : "none" | "horizontal" | "vertical" | "tile",
 *
 *     rightAdType        : "Image" | "Flash",
 *     rightForeFile      : string,
 *     rightForeWidth     : int,
 *     rightForeHeight    : int,
 *     rightForeAttachment: "fixed" | "scroll",
 *     rightBackImage     : string,
 *     rightBackHAlign    : "left" | "center" | "right",
 *     rightBackVAlign    : "top" | "center" | "bottom",
 *     rightBackRepeat    : "none" | "horizontal" | "vertical" | "tile",
 *
 *     linkLeft           : string,
 *     linkRight          : string,
 *
 *     backgroundColor    : string,
 *     backgroundImage    : string
 *     backgroundAttachment: "fixed" | "scroll",
 *     backgroundSize     : "auto" | "contain" | "cover",
 *     backgroundHAlign   : "left" | "center" | "right",
 *     backgroundVAlign   : "top" | "center" | "bottom",
 *     backgroundRepeat   : "none" | "horizontal" | "vertical" | "tile",
 *
 *     trackingPixelURL   : string,
 *   }
 * } properties - Skin広告の情報
 */
function displaySkinAds(properties) {

  // Store 以下ではskin広告出さない
  const pathname = location.pathname
  if (pathname.indexOf("/store") > -1) {
    return
  }

  /*
   * 基本的にSK広告はページロード時には表示を待機している状態にある。
   * propertiesが設定されていないSkin(DummySkinと呼ぶ)が
   * 配信された時に初めてSK広告を表示する。
   * つまり、通常のSkin広告が配信された時はSKは非表示のままになり、
   * DummySkinが配信された時はSKを表示することになる。
   */
  if (properties == null) {
    // SKを表示する
    displaySKAds();
    return;
  }

  // 必須情報が全て渡されているか確認
  const required = [
    "foreAlign",
    "leftAdType",
    "leftForeFile",
    "leftForeWidth",
    "leftForeHeight",
    "leftForeAttachment",
    "rightAdType",
    "rightForeFile",
    "rightForeWidth",
    "rightForeHeight",
    "rightForeAttachment",
    "linkLeft",
    "linkRight",
    "backgroundColor"
  ];
  for (let i = 0, len = required.length; i < len; i++) {
    if (!(required[i] in properties)) {
      // １つでも漏れがあったらSKを表示して終わり。
      displaySKAds();
      return;
    }
  }

  // jQueryオブジェクトを取得
  const requiredDOM = [];
  requiredDOM.push($skinLeft = $("#ad-skin-left"));
  requiredDOM.push($skinRight = $("#ad-skin-right"));
  requiredDOM.push($skinLeftFixed = $("#ad-skin-left-fixed-block"));
  requiredDOM.push($skinRightFixed = $("#ad-skin-right-fixed-block"));
  requiredDOM.push($skinLeftAbsolute = $("#ad-skin-left-absolute-block"));
  requiredDOM.push($skinRightAbsolute = $("#ad-skin-right-absolute-block"));
  for (let i = 0, len = requiredDOM.length; i < len; i++) {
    if (!requiredDOM[i].exists()) {
      // DFPの配信が早すぎてDOMが読み込まれていないので、ペンディング
      setTimeout(displaySkinAds, 50, properties);
      return;
    }
  }

  // bodyのclassをSkin広告配信用にセット
  $(document.body).addClass("ad-skin");

  // SKのDIVを非表示、SkinのDIVを表示
  $(".side-koukoku").hide();
  $(".ad-skin-side").show();

  // 広告のサイズを設定
  adsLeftWidth = properties.leftForeWidth;
  adsLeftHeight = properties.leftForeHeight;
  adsRightWidth = properties.rightForeWidth;
  adsRightHeight = properties.rightForeHeight;
  $skinLeftAbsolute.css({width: adsLeftWidth});
  $skinRightAbsolute.css({width : adsRightWidth});
  $skinLeft.css("height", adsLeftHeight);
  $skinRight.css("height", adsRightHeight);

  // 広告の表示方法を設定
  switch (properties.foreAlign) {
    case "innerside": foregroundAlign = "innerside"; break;
    case "outerside": foregroundAlign = "outerside"; break;
    default: foregroundAlign = "centered"; break;
  }
  if (properties.leftForeAttachment === "fixed") {
    $skinLeftFixed.css("position", "fixed");
  }
  if (properties.rightForeAttachment === "fixed") {
    $skinRightFixed.css("position", "fixed");
  }

  // ForegroundFileを表示
  if (properties.leftAdType === "Image") {
    // 画像
    const $linkobj = $("<a>").attr({
      target: "_blank",
      href  : properties.linkLeft
    });
    const $imgobj = $("<img>").attr({
      src   : properties.leftForeFile,
      width : adsLeftWidth,
      height: adsLeftHeight
    });
    $linkobj.append($imgobj);
    $skinLeftFixed.append($linkobj);
  } else {
    // フラッシュ
    const $swfobj = $("<embed>").attr({
      type  : "application/x-shockwave-flash",
      src   : properties.leftForeFile,
      width : adsLeftWidth,
      height: adsLeftHeight,
      wmode : "transparent"
    });
    $skinLeftFixed.append($swfobj);
  }
  if (properties.rightAdType === "Image") {
    // 画像
    const $linkobj = $("<a>").attr({
      target: "_blank",
      href  : properties.linkRight
    });
    const $imgobj = $("<img>").attr({
      src   : properties.rightForeFile,
      width : adsRightWidth,
      height: adsRightHeight
    });
    $linkobj.append($imgobj);
    $skinRightFixed.append($linkobj);
  } else {
    // フラッシュ
    const $swfobj = $("<embed>").attr({
      type  : "application/x-shockwave-flash",
      src   : properties.rightForeFile,
      width : adsRightWidth,
      height: adsRightHeight,
      wmode : "transparent"
    });
    $skinRightFixed.append($swfobj);
  }

  // BackgroundImageの表示設定
  if (properties.leftBackImage) {
    const $obj = $("#ad-skin-bg-left");
    $obj.css({
      "background-image"   : `url(${properties.leftBackImage})`,
      "background-position":
        `${properties.leftBackHAlign ? properties.leftBackHAlign : "center"} ${ 
        properties.leftBackVAlign ? properties.leftBackVAlign : "top"}`
    });
    switch (properties.leftBackRepeat) {
      case "horizontal":
        $obj.css("background-repeat", "repeat-x");
        break;
      case "vertical":
        $obj.css("background-repeat", "repeat-y");
        break;
      case "tile":
        $obj.css("background-repeat", "repeat");
        break;
      default:
        $obj.css("background-repeat", "no-repeat");
        break;
    }
  }
  if (properties.rightBackImage) {
    const $obj = $("#ad-skin-bg-right");
    $obj.css({
      "background-image"   : `url(${properties.rightBackImage})`,
      "background-position":
      `${properties.rightBackHAlign ? properties.rightBackHAlign : "center"} ${ 
      properties.rightBackVAlign ? properties.rightBackVAlign : "top"}`
    });
    switch (properties.rightBackRepeat) {
      case "horizontal":
        $obj.css("background-repeat", "repeat-x");
        break;
      case "vertical":
        $obj.css("background-repeat", "repeat-y");
        break;
      case "tile":
        $obj.css("background-repeat", "repeat");
        break;
      default:
        $obj.css("background-repeat", "no-repeat");
        break;
    }
  }

  // 背景の設定
  const backgroundInfo = { "background-color": properties.backgroundColor };
  if (properties.backgroundImage) {
    backgroundInfo["background-image"] = `url(${properties.backgroundImage})`;
    backgroundInfo["background-position"] =
        `${properties.backgroundHAlign ? properties.backgroundHAlign : "center"} ${ 
        properties.backgroundVAlign ? properties.backgroundVAlign : "top"}`;
    backgroundInfo["background-attachment"] = properties.backgroundAttachment ? properties.backgroundAttachment : "fixed";
    backgroundInfo["background-size"] = properties.backgroundSize ? properties.backgroundSize : "cover";
    switch (properties.backgroundRepeat) {
      case "horizontal":
        backgroundInfo["background-repeat"] = "repeat-x";
        break;
      case "vertical":
        backgroundInfo["background-repeat"] = "repeat-y";
        break;
      case "tile":
        backgroundInfo["background-repeat"] = "repeat";
        break;
      default:
        backgroundInfo["background-repeat"] = "no-repeat";
        break;
    }
  }
  $(document.body).css(backgroundInfo);

  // TrackingPixelの表示
  if (properties.trackingPixelURL) {
    $trackingPixel = $("<img>").attr({
      src   : properties.trackingPixelURL,
      width : "1px",
      height: "1px",
    }).css({
      position: "absolute",
      display: "none",
    });
    $skinLeft.append($trackingPixel);
  }

  // 表示
  resizeSkinAds();

  // 背景情報を保存
  saveIntoCookie("bgInfo", backgroundInfo);

  // リサイズイベントを設定
  $(window).resize(function() {
    resizeSkinAds();
  });
}


const skTagStack = [];
const skRefreshSlots = {};

/**
 * 待機しているSK広告を表示する
 */
function displaySKAds() {
  // クッキーからSkinに関する情報を削除
  Cookies.remove(getCookieKey("bgInfo"));
  Cookies.remove(getCookieKey("showInfo"));

  // スキン系の設定を除去
  $(document.body).removeClass("ad-skin");
  $(".ad-skin-side").hide();

  // SKを表示
  skTagStack.forEach(function(tag) {
    googletag.cmd.push( function() {googletag.display(tag);} ); // eslint-disable-line no-undef
    if (tag in skRefreshSlots) {
      window.MAL.adRefresh.setupRefresh(skRefreshSlots[tag]);
    }
  });
}

/**
 * SK広告を待機させる。
 * ここにpushされたものはSkin広告が配信されない場合に表示される。
 *
 * @param {string} tag - SKを表示するDIVタグのID
 */
function pushSKTag(tag) {
  skTagStack.push(tag);
}

/**
 * SK広告をrefreshさせたいときにstackしておく
 *
 * @param {string} tag - SKを表示するDIVタグのID
 * @param {object} slot - googletag.defineSlotの返り値
 */
function pushRefreshSkSlot(tag, slot) {
  skRefreshSlots[tag] = slot;
}

/**
 * ページがレンダリングされる前にSkin広告の配信を準備するメソッド。
 * スロットコードを保存して、背景色・広告サイズをクッキーから取り出して反映。
 * 毎回Skinから配信されるのを待って動的に背景色やサイズを変えると
 * チラつきが発生してしまうためこのような仕様にした。
 *
 * @param {string} code - スロットコード
 */
function prepareForSkin(code) {
  slotCode = code;

  const bgInfo = Cookies.getJSON(getCookieKey("bgInfo"));
  const showInfo = Cookies.getJSON(getCookieKey("showInfo"));
  if (bgInfo != null) {
    // 背景色を変更
    $("body").css(bgInfo);
  }
  if (showInfo != null) {
    if (showInfo.right == 0) {
      // 左のスキンのみ表示している状態なので、横幅を設定して事前に表示してやる。
      // こうすることで、コンテンツがロードされる時に左右にブレることを抑制。
      $("#ad-skin-left-anchor").css({
        width  : showInfo.left,
        display: "block"
      });
      $(document.body).addClass("ad-skin");
      $(".ad-skin-side").show();
    }
  }
}

function getCookieKey(key) {
  return `Skin-${slotCode}-${key}`;
}

function saveIntoCookie(key, value) {
  const expire = new Date();
  expire.setTime(expire.getTime() + 30 * 60 * 1000);  // 30分後
  Cookies.set(getCookieKey(key), value, {expires : expire});
}

// スキン広告を画面幅に応じてリサイズするメソッド
function resizeSkinAds() {
  const winWidth = $(window).width();
  let leftSpaceWidth = 0, rightSpaceWidth = 0;

  if (winWidth >= 1200) {
    // 1200以上の場合にスキン広告が出る
    const halfWidth = (winWidth - 1060) / 2;
    leftSpaceWidth = Math.floor(halfWidth);
    rightSpaceWidth = Math.ceil(halfWidth);
  }

  // 広告領域を更新
  $skinLeft.width(leftSpaceWidth);
  $skinRight.width(rightSpaceWidth);

  // 広告オブジェクトの位置を調整
  let leftOffset = 0, rightOffset = 0;
  switch (foregroundAlign) {
    case "innerside":
      leftOffset = 0;
      rightOffset = 0;
      break;
    case "outerside":
      leftOffset = leftSpaceWidth - adsLeftWidth;
      rightOffset = rightSpaceWidth - adsRightWidth;
      break;
    default: // centered
      leftOffset = leftSpaceWidth;
      rightOffset = rightSpaceWidth;
      break;
  }
  $skinLeftAbsolute.css("right", `${Math.min(leftOffset, 0)}px`);
  $skinRightAbsolute.css("left", `${Math.min(rightOffset, 0)}px`);

  // 表示情報を保存
  saveIntoCookie("showInfo", {
    left : leftSpaceWidth,
    right: rightSpaceWidth
  });
}

// メソッドをエクスポート
window.MAL.SkinAd = {
  displaySkinAds: displaySkinAds,
  prepareForSkin: prepareForSkin,
  pushSKTag     : pushSKTag,
  pushRefreshSkSlot: pushRefreshSkSlot,
};

