// PHPから値を受け渡す際に利用するグローバルな名前を宣言しておくスペース。
// ここにはPC/SPで共通的なものを指定。
// anNamespace: null
const MAL = {
  braintree: null,
  stripe: null,
  /**
   * @see Helper\Ad\DoubleClick\Unit::NETWORK_CODE
   */
  DFP_NETWORK_CODE: "84947469",
  /**
   * smalllight validator key
   * @see Helper\Url::assetImage
   */
  SLVK: null,
  /**
   * import common.cdnUrl from config
   */
  CDN_URL: null,
  /**
   * TUTORIAL
   */
  CURRENT_TUTORIAL_STEP_ID: null,
  USER_NAME: null,
  FACEBOOK: {
    APP_ID: null,
    API_VERSION: null,
  },
  /**
   * import common.gtm_id from config
   */
  GTM_ID: null,
  /**
   * Review
   */
  REACTIONS_IMAGE_PATH: "/images/emoticon/72x72/",
  REACTIONS_EMOTICON: {
    1: '1f44d.png',
    2: '2764.png',
    3: '1f602.png',
    4: '1f928.png',
    5: '1f4a1.png',
    6: '1f4dd.png',
    7: '1f3a8.png',
  },
  REACTIONS_TITLE: {
    1: 'Nice',
    2: 'Love it',
    3: 'Funny',
    4: 'Confusing',
    5: 'Informative',
    6: 'Well-written',
    7: 'Creative',
  },
  fancyBox: {
    onClose: null
  }
}

export default MAL
