import _ from "lodash";

function redirect(url, opts) {
  opts = _.assign({
    time   : 1000, // redirectまでの時間(in milliseconds)
    replace: true, // location.replaceするかどうか
  }, opts || {});

  setTimeout(() => {
    if (opts.replace) {
      window.location.replace(url);
    } else {
      window.location.href = url;
    }
  }, opts.time);
}

export default {
  redirect,
}
