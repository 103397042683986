const $ = window.$
import axios from "axios"
import CsrfProtector from "./modules/CsrfProtector"

// ajax通信するときに送信データにCSRFトークンを埋めこむ
$.ajaxPrefilter(function(options) {
  if (!options.crossDomain && options.type.toUpperCase() !== "GET") {
    const token = CsrfProtector.getToken()
    if (token) {
      if (typeof FormData !== "undefined" && options.data instanceof FormData) {
        options.data.append("csrf_token", token)
        return
      }
      try {
        // JSON形式の文字列
        const json       = JSON.parse(options.data)  // parseできなかったらcatchでQuery形式の処理に飛ぶ
        json["csrf_token"] = token
        options.data = JSON.stringify(json)
      } catch (e) {
        // application/x-www-form-urlencoded形式の文字列
        let query = options.data
        if (!query) {
          query = ""
        } else {
          query += "&"
        }
        options.data = `${query}csrf_token=${token}`
      }
    }
  }
})

// axiosにCSRF tokenを埋めこむ
axios.interceptors.request.use(config => {
  const token = CsrfProtector.getToken()
  config.headers["Content-Type"] = "application/json"
  if (config.method.toLowerCase() !== "get") {
    config.withCredentials = true
    config.headers["X-CSRF-Token"] = token
  }
  return config
})

$(function() {
  // formのsubmitをフックしてCSRFトークンを埋め込む
  $("form").submit(function() {
    CsrfProtector.injectTokenToForm(this)
  })
  // SP forum timeline view用
  $('.forum.timeline').on('submit', 'form.forum-post-message.in-timeline', function() {
    CsrfProtector.injectTokenToForm(this)
  })

  // submitメソッドを書き換えてCSRFトークンを埋め込む処理を入れる
  HTMLFormElement.prototype._nativeSubmit = HTMLFormElement.prototype.submit
  HTMLFormElement.prototype.submit = function() {
    CsrfProtector.injectTokenToForm(this)
    this._nativeSubmit.apply(this, arguments)
  }
})
