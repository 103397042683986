import _ from "lodash";
import $ from "jquery";
import embed from "./modules/embed";

/**
 * 配下のa要素について、class属性にopts.classNameが
 * - 含まれる場合、リンク先(href)
 * - 含まれない場合、要素のtextContent
 * を視聴URLとして、埋め込みコンテンツに変換する。
 *
 * サイズ等はdata属性で指定することも可能。
 * 受理するdata属性:
 * - data-width: 幅
 * - data-aspect: アスペクト比
 * - data-height: 高さ。aspectより優先される。
 * - data-align: 位置。"center", "left" or "right"。
 *
 * @param {object} opts コード冒頭を参照
 * @example
 * 変換されるHTML例 (opts.targetClassが"embed"の時):
 * <a href="https://www.youtube.com/watch?v=qloTsm63QDU" class="embed">Watch now!</a>
 * <a href="https://www.youtube.com/watch?v=qloTsm63QDU">
 *   https://www.youtube.com/watch?v=qloTsm63QDU
 * </a>
 * <a href="https://www.youtube.com/watch?v=qloTsm63QDU" data-width="300" data-align="right">
 *   https://www.youtube.com/watch?v=qloTsm63QDU
 * </a>
 * 変換されないHTML例:
 * <a href="https://www.youtube.com/watch?v=qloTsm63QDU">Watch now!</a>
 */
$.fn.url2embed = function(opts) {
  opts = _.assign({
    // デフォルトの幅
    defaultWidth: 420,
    // デフォルトのアスペクト比
    defaultAspect: 1.33,
    // "left", "right" or "center"
    defaultAlign: "left",
    // widthが親要素より大きい時に親要素のサイズに合わせるか
    fitParent: true,
    // 変換明示の際のクラス名
    targetClass: "embed",
    // プリセット設定
    predefined: {
      // クラス名: {width, aspect, height, align}
    }
  }, opts || {});

  $(this).each(function() {
    $(this).find("a").each(function() {
      const $a = $(this);

      const predefined = _.find(opts.predefined, (unused, className) => $a.hasClass(className)) || {};

      let width = $a.data("width") || predefined.width || opts.defaultWidth;
      if (opts.fitParent) {
        width = Math.min(width, $a.parent().width());
      }
      const aspect = $a.data("aspect") || predefined.aspect || opts.defaultAspect;
      const height = $a.data("height") || predefined.height || null; // embed()で計算される
      const align = $a.data("align") || predefined.align || opts.defaultAlign;

      const $wrapper = $("<div>");
      const willEmbed = embed({
        url: ($a.hasClass(opts.targetClass) ? $a.attr("href") : $a.text()).trim(),
        width: width,
        aspect: aspect,
        height: height,
        onDidCreateScript(script) {
          $wrapper.append(script);
        },
        onDidCreateHtml(html) {
          const $el = $(html).css({block: "inline-block"});
          $wrapper.css("text-align", align);
          $wrapper.append($el);
        }
      });
      if (willEmbed) {
        $a.hide().after($wrapper);
      }
    });
  });
};
