"use struct";

import $ from "jquery";

(function () {
  const NETWORKID = '3948';

  const link = (siteId) => {
    if (siteId === null) return false;

    let attributes = [
      {
        src: 'https://tagmanager.smartadserver.com/' + NETWORKID + '/' + siteId + '/smart.prebid.js',
        async: 'async',
        type: 'text/javascript',
      },
      {
        src: 'https://ced.sascdn.com/tag/' + NETWORKID + '/smart.js',
        async: 'async',
        type: 'application/javascript',
      }
    ];

    for (let index of Object.keys(attributes)) {
      $('<script>').attr(attributes[index]).appendTo('head');
    };
  };

  const init = (siteId, pageId, formats, target) => {
    if (siteId === null || pageId === null || !formats.length) return false;

    const SAS = window.sas || {};

    var recall_flg = true;

    SAS.cmd = SAS.cmd || [];
    SAS.cmd.push(function () {
      SAS.setup({ networkid: NETWORKID, domain: "https://www18.smartadserver.com", async: true, renderMode: 2 });
    });

    let tagIds = [];
    formats.forEach((format) => {
      tagIds.push(format.tagId);
    });

    // target で 1つの Key に複数 Value を設定するための処理
    SAS.setTargeting({
      target: target,
      mode: 1,
      tagIds: tagIds
    })

    SAS.cmd.push(function (git ) {
      SAS.call("onecall", {
        siteId: siteId,
        pageId: pageId,
        formats: formats,
      }, {
        onNoad: function(data){
          if (recall_flg) {
            SAS.refresh(data.formatId);
            recall_flg = false;
          } else {
            var element = document.getElementById(data.tagId);
            if (element) {
              element.style.height = 'auto';
              element.style.width = 'auto';
            }
          }
        },
        onAd: function(data){
          var element = document.getElementById(data.tagId);
          if (element) {
            element.style.height = 'auto';
            element.style.width = 'auto';
          }
        }
      });
    });

    for (let index of Object.keys(formats)) {
      const FORMAT = formats[index];

      SAS.cmd.push(function () {
        SAS.render(FORMAT.tagId);
      });

      if (FORMAT.anchor) {
        const ANCHOR = window.MAL.ad.anchor;
        ANCHOR.shouldSilence() ? '' : ANCHOR.prepare(null, true);
      };
    };
  };

  const getTarget = ($element) => {
    let decodedTargets     = decodeURIComponent($element.attr('data-ad-target'));
    let jsonDecodedTargets = JSON.parse(decodedTargets);
    return jsonDecodedTargets;
  }

  const getInfo = () => {
    let siteId = null;
    let pageId = null;
    let formats = [];
    let target = {};

    $('.ad-sas').each((index, element) => {
      formats[index] = {
        id: $(element).attr('data-ad-id'),
        tagId: $(element).attr('id'),
        anchor: $(element).is('[data-anchor]')
      };

      if (index === 0) {
        pageId = $(element).attr('data-ad-pid');
        siteId = $(element).attr('data-ad-sid');
        target = getTarget($(element));
      };
    });

    return {
      siteId: siteId,
      pageId: pageId,
      formats: formats,
      target: target,
    }
  };

  const execute = () => {
    let info = {};
    const promise = new Promise((resolve) => {
      document.addEventListener('DOMContentLoaded', () => {
        info = getInfo();
        link(info.siteId);
        resolve();
      }, false);
    });

    promise.then(() => window.addEventListener('load', () => {
      init(info.siteId, info.pageId, info.formats, info.target);
    }, false));
  };

  execute();
})();
