"use strict";

import Visibility from "visibilityjs"

import elVis from "./element-visibility"

// リフレッシュ間隔
const REFRESH_INTERVAL_SECONDS = 60

/**
 * ユニットのリフレッシュを開始する。
 * リフレッシュ時のリクエストで純広告などが配信されないように、ラベルで除外設定をする。
 *
 * @param slot {googletag.Slot} スロットオブジェクト
 */
function setupRefresh(slot) {
  // const slotElement = document.getElementById(slot.getSlotElementId())

  // タイマーを開始。visible状態のみタイマーが走る。
  Visibility.every(REFRESH_INTERVAL_SECONDS * 1000, () => {
    /*
     * 画面内のみリフレッシュする場合はこの条件文を入れる。
     * 2016/8/25現在、この条件でリフレッシュを制限するとRubiconのレベニューが落ちてしまっているので、コメントアウトする。
     * if (elVis.isElementInViewport(slotElement, 0.5)) {
     * }
     */
    window.googletag.pubads().refresh([slot])
  })
}

/**
 * Refreshes a direct tag in regular intervals.
 *
 * @param tag.targetFrameId The target iframe's id
 * @param tag.code The tag's code
 */
function setupDirectTagRefresh(tag) {
  Visibility.every(REFRESH_INTERVAL_SECONDS * 1000, () => {
    reloadDirectTag(tag)
  })
}

/**
 * (Re-) Loads a direct tag's code into its target frame.
 *
 * @param tag.targetFrameId The target iframe's id
 * @param tag.code The tag's code
 */
function reloadDirectTag(tag) {
  const iframe = document.getElementById(tag.targetFrameId)
  if (iframe.nodeName === 'IFRAME') {
    const doc = iframe.contentWindow.document
    doc.open()
    doc.write(`<html><head><title></title></head><body>${tag.code}</body></html>`)
    doc.close()
  }
}

window.MAL.adRefresh = {
  setupRefresh,
  setupDirectTagRefresh,
  reloadDirectTag,
}
