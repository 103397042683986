
import noty from "noty";

function showError(text) {
  return noty({
    text    : text,
    theme   : "relax",
    type    : "error",
    template: "<div class=\"noty_message\"><span class=\"noty_text\"></span><span class=\"noty_close\"><i class=\"fa-solid fa-xmark\"></i></span></div>",
    closeWith: ["button"],
    layout  : "bottomRight"
  });
}

export default {showError}
