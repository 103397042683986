/**
 * Checks whether an element is in the viewport (loosely based on http://stackoverflow.com/a/7557433).
 *
 * @param {Element} element - The element to check.
 * @param {number} inViewportAreaThreshold - The minimum amount of area (as a fraction 0 in the interval (0.0, 1.0]).
 * @return {boolean} True if at least inViewportAreaThreshold * area of the element is in the viewport.
 */
function isElementInViewport(element, inViewportAreaThreshold) {
  if (inViewportAreaThreshold <= 0.0 || inViewportAreaThreshold > 1.0) {
    throw new RangeError(`Invalid range for inViewportAreaThreshold: ${inViewportAreaThreshold} is not in (0.0, 1.0]`)
  }

  const rect = element.getBoundingClientRect()
  const area = rect.width * rect.height

  if (area === 0) {
    return false;
  }

  const viewportRight = window.innerWidth || document.documentElement.clientWidth
  const viewportBottom = window.innerHeight || document.documentElement.clientHeight

  const inViewportWidth = (rect.right > viewportRight ? viewportRight : rect.right) - (rect.left < 0 ? 0 : rect.left)
  const inViewporHeight = (rect.bottom > viewportBottom ? viewportBottom : rect.bottom) - (rect.top < 0 ? 0 : rect.top)

  return inViewportWidth * inViewporHeight >= area * inViewportAreaThreshold;
}

export default { isElementInViewport }