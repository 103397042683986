"use strict";

import * as login from './modules/ga/login'
import * as register from './modules/ga/register'
import * as membership from './modules/ga/membership'

const $ = window.$;

$(function() {
  $(".js-share-button-tracking").on("click", function() {
    const $this = $(this);
    window.ga("send", "social", {
      socialNetwork: $this.data("ga-network"),
      socialAction: "share",
      socialTarget: location.href, // or get from og:url
      // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference?hl=ja#screenName
      screenName: $this.data("ga-screen")
    });
  });
});

/*
 * add event to window
 */

/*
 * login
 */
// 2.未ログインの場合
window['ga_notlogin'] = login.ga_notlogin;
// 2-1-1.ソーシャルログインの場合
window['ga_sns'] = login.ga_sns;
// 2-1-2.IDログインの場合
window['ga_login'] = login.ga_login;

/*
 * register
 */
// 2-2.会員登録ボタン
window['ga_registration'] = register.ga_registration;

/*
 * membership
 */
// 2-3.MALバナー
window['ga_mal_banner'] = membership.ga_mal_banner;
// 3-1-1.クレカ登録
window['ga_credit'] = membership.ga_credit;
// 3-1-2.Paypal登録
window['ga_paypal'] = membership.ga_paypal;
