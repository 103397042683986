
import notification from "../../common/modules/notify";
import $ from "jquery";

// twitter permissionを設定するときにerrorがおきたときに呼ばれる
let permitTwitterOnError = function() {};

/**
 * リストの更新情報をSNSに投稿する
 *
 * @param data
 * @param workType
 * @param workId
 * @param callbacks - {
 *   onSuccess : 投稿成功時にSNS毎に呼ばれる
 *   onError   : 投稿失敗時にSNS毎に呼ばれる
 *   onComplete: allowの時はすぐに呼ばれ、askの時はConfirmのポップアップが閉じられた時に呼ばれる
 * }
 */
function postListUpdates (data, workType, workId, callbacks = {}) {

  // 定義されていないコールバックを埋める
  if (!callbacks.onSuccess) {
    callbacks.onSuccess = function () {};
  }
  if (!callbacks.onError) {
    callbacks.onError = function () {};
  }
  if (!callbacks.onComplete) {
    callbacks.onComplete = function () {};
  }

  // データがなかったら何もしない
  if (!data) {
    callbacks.onComplete();
    return;
  }

  const postFacebook = function (postData, actionLogData) {
    postToFacebook(postData, actionLogData, false, function (data) {
      if (data.isSuccess) {
        callbacks.onSuccess("Facebook", {});
      } else {
        callbacks.onError("Facebook", {});
        switch (data.result) {
          case "require_authentication":
            notification.showError("Failed to post to Facebook. Post permission is required. <br>Please enable it <a href='/editprofile.php?go=listpreferences' target='_blank'>here</a>");
            break;
          case "require_login":
            notification.showError("Failed to post to Facebook. Please login to Facebook and try again.");
            break;
          default:
            notification.showError("Failed to post to Facebook. Internal server error");
            break;
        }
      }
    });
  };

  const postTwitter = function (postData, actionLogData, beforeSend) {
    if (!beforeSend) {
      beforeSend = function () {};
    }
    return $.ajax({
      url: "/sns/post/twitter.json",
      type: "POST",
      data: JSON.stringify({
        body: postData.body,
        action_log_data: {
          event_id: actionLogData.event_id,
          work_type: actionLogData.work_type,
          work_id: actionLogData.work_id,
          is_confirmed: actionLogData.is_confirmed
        },
      }),
      dataType: "json",
      contentType: "application/json",
      cache: false,
      timeout: 10000,
      beforeSend: beforeSend,
    });
  };

  switch (data.post_type) {
    case "allow":
      if (data.post_data.facebook) {
        postFacebook(data.post_data.facebook, data.action_log_data);
      }
      if (data.post_data.twitter) {
        postTwitter(data.post_data.twitter, data.action_log_data)
          .done(function (data) {
            callbacks.onSuccess("twitter", data);
          })
          .fail(function (xhr) {
            callbacks.onError("twitter", xhr.responseJSON, xhr.status);
            switch (xhr.status) {
              case 400:
                notification.showError(xhr.responseJSON.errors[0].message);
                break;
              case 401:
                notification.showError("Login required.");
                break;
              case 403:
                notification.showError("Failed to post to Twitter. Post permission is required. <br>Please enable it <a href='/editprofile.php?go=listpreferences' target='_blank'>here</a>");
                break;
              default:
                notification.showError("Failed to post to Twitter. Internal server error");
            }
          })
        ;
      }
      callbacks.onComplete();
      break;
    case "ask_every_time":
      var isPostedToFacebook = false;
      popSnsPost(data.post_data, {
        onYes: function (doSave, jqObjects, twitterOptions) {
          if (doSave) {
            savePostType(workType, workId, "allow");
          }

          if (!isPostedToFacebook && data.post_data.facebook) {
            postFacebook(data.post_data.facebook, data.action_log_data);
            isPostedToFacebook = true;
          }
          if (data.post_data.twitter) {
            let
              $yes = jqObjects.yes, $no = jqObjects.no
            , $indicator = jqObjects.indicator
            , $error     = twitterOptions.error
            ;
            data.post_data.twitter.body = twitterOptions.message;

            postTwitter(data.post_data.twitter, data.action_log_data, function () {
                $yes.hide();
                $no.hide();
                $indicator.show();
                $error.hide();
              })
              .always(function () {
                $yes.show();
                $no.show();
                $indicator.hide();
              })
              .done(function (data) {
                callbacks.onSuccess("twitter", data);
                jqObjects.fancybox.close();
              })
              .fail(function (xhr) {
                callbacks.onError("twitter", xhr.responseJSON, xhr.status);
                switch (xhr.status) {
                  case 400:
                    $error.text(xhr.responseJSON.errors[0].message);
                    $error.show();
                    break;
                  case 401:
                    jqObjects.fancybox.close();
                    notification.showError("Login required.");
                    break;
                  case 403:
                    jqObjects.fancybox.close();
                    notification.showError("Failed to post to Twitter. Post permission is required. <br>Please enable it <a href='/editprofile.php?go=listpreferences' target='_blank'>here</a>");
                    break;
                  case 429:
                    jqObjects.fancybox.close();
                    notification.showError(xhr.responseJSON.errors[0].message);
                    break;
                  default:
                    jqObjects.fancybox.close();
                    notification.showError("Failed to post to Twitter. Internal server error");
                }
              })
            ;
          } else {
            // twitter以外は閉じていい
            jqObjects.fancybox.close();
          }
        },
        onNo: function (doSave) {
          if (doSave) {
            savePostType(workType, workId, "disallow");
          }
        },
        onClose: function () {
          callbacks.onComplete();
        }
      });
      break;
  }
}

// 個別アイテムごとに投稿タイプを保存するメソッド
function savePostType (workType, workId, postType) {
  $.post("/ownlist/post_type/save.json", JSON.stringify({
    work_type : workType,
    work_id   : parseInt(workId),
    post_type : postType
  }))
  .fail(function () {
    notification.showError("Failed to save post setting.");
  });
}

// 投稿するか聞くポップアップを表示する
function popSnsPost (data, callbacks) {

  const postTwitter = data.twitter != undefined;
  let snsNames = "<b>Twitter</b>";

  let twitterComment = "";
  if (postTwitter) {
    twitterComment = `
    <span style="display:inline-block; font-size:12px; margin-right:4px;">Comment for Twitter:</span>
    <span id="js-twitter-error" style="color:#CB3D2F;display:none;"></span>
    <br>
    <textarea id="js-twitter-message" type="text" style="font-size:12px; width: 440px; height: 48px;">${data.twitter.body}</textarea>
    `;

  }

  const tmpl = `
  <div class="js-sns-post-block" xmlns="http://www.w3.org/1999/html">
    <style>
    .box-shadow {box-shadow: 0px 0px 0px 1px #1d439b, 0px 0px 0px 10px #e1e7f5;}
    .dark-mode .box-shadow {box-shadow: 0px 0px 0px 10px #353535;}
    .inputButton {border-style: solid;border-top-width: 1px;border-left-width: 1px;border-bottom-width: 1px;border-right-width: 1px;border-top-color: #6c8cd8;border-left-color: #6c8cd8;border-bottom-color: #2e51a2;border-right-color: #2e51a2;background-color: #4165ba;color: #FFFFFF;font-size: 11px;padding: 1px 3px;font-family: "lucida grande", tahoma, verdana, arial, sans-serif;}
    </style>
    <div style="display:block;margin:12px;padding:12px;width:450px;" class="box-shadow">
      <form>
        <div style="font-size:12px;text-align:left;">Would you like to share your progress update to ${snsNames}?</div>
          <div style="font-size:12px; padding-top:12px; text-align:left;">
          ${twitterComment}
            <div style="padding:8px;text-align:center;">
              <input type="button" value="Yes" class="inputButton js-sns-fancybox-yes" style="margin-right:18px;">
              <input type="button" value="No" class="inputButton js-sns-fancybox-no">
              <i id="js-fancybox-indicator" class="fa-solid fa-spinner fa-spin" style="display:none;"></i>
            </div>
            <div style="font-size:12px; text-align: center;">
              <input type="checkbox" id="js-sns-save-check"><label for="js-sns-save-check">Always apply above setting to this series and never ask again.</label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  `;

  const options = {
    content: tmpl,
    autoSize: true,
    scrolling: "no",
    title: null,
    onComplete: function () {
      // Yes
      let $yes = $(".js-sns-fancybox-yes"), $no = $(".js-sns-fancybox-no"), $indicator = $("#js-fancybox-indicator");

      $yes.on("click", function () {
        const jqObjects = {
          fancybox: $.fancybox, yes: $yes, no: $no, indicator: $indicator
        };

        // for twitter
        let twitterOptions = {};
        if ($("#js-twitter-message").exists()) {
          twitterOptions = {
            message: $("#js-twitter-message").val(),
            error: $("#js-twitter-error"),
          };
        }

        callbacks.onYes($("#js-sns-save-check").prop("checked"), jqObjects, twitterOptions);
      });

      // No
      $no.on("click", function () {
        callbacks.onNo($("#js-sns-save-check").prop("checked"));
        $.fancybox.close();
      });

      // Fancyboxによってキー入力がキャプチャされてしまい、Textarea内で矢印キーでカーソルを移動できないのでkeydownイベントをunbindしてしまう。
      $(document).off("keydown");
      $("#js-twitter-message").focus();
    },
    onClosed: function () {
      callbacks.onClose();
    },
  };

  //fancybox
  $.fancybox(options);
}

// FacebookのSDKをロード
function initializeFacebook () {

  window.fbAsyncInit = function() {
    FB.init({
      appId: window.MAL.FACEBOOK.APP_ID,
      xfbml: true,
      version: window.MAL.FACEBOOK.API_VERSION
    });
  };

  (function(d, s, id){
  	let js, fjs = d.getElementsByTagName(s)[0];
  	if (d.getElementById(id)) {return;}
  	js = d.createElement(s); js.id = id;
  	js.src = "//connect.facebook.net/en_US/sdk.js";
  	fjs.parentNode.insertBefore(js, fjs);
  }(document, "script", "facebook-jssdk"));

}

// twitterへのpermission操作後に小窓から呼ばれる
function permitTwitter(isSuccess, error) {
  if (isSuccess) {
    return;
  }

  // error
  let msg;
  switch (error) {
    // see Service\OwnList\Twitter
    case "before_connection":
      msg = "Not connected with Twitter. <br>Please enable it <a href='/editprofile.php?go=listpreferences' target='_blank'>here</a>";
      break;
    case "unknown_user":
      msg = "Not match the user ID of Twitter. <br> If you want to associate another Twitter account on MAL, please disconnect and connect again from <a href='/editprofile.php?go=myoptions' target='_blank'>here</a>";
      break;
    case "deny_authentication":
      msg = "Can't get your access token from Twitter.";
      break;
    case "not_match_token":
      msg = "Invalid oauth_token.";
      break;
    default:
      msg = "Internal server error. <br>Please try again later.";
  }
  notification.showError(msg);
  permitTwitterOnError(error);
}

// メソッドをエクスポート
window.MAL.SNSFunc = {
  initializeFacebook: initializeFacebook,
  postListUpdates: postListUpdates,
  permitTwitter: permitTwitter,
  setPermitTwitterOnError: function (callback) {
    permitTwitterOnError = callback;
  },
};

